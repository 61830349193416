<template>
  <div class="row m-0">
    <div class="col-md-12 col-lg-4 chatNow">
      <div class="text-center">
        <h3>Have a Quick Question?</h3>
        <a id="appoint-chat-now" @click.prevent="handleChatNow">Chat Now</a>
        <p>Our jewellery specialists are happy to help 24/7 through live chat.</p>
      </div>
    </div>
    <div class="col-md-12 col-lg-8 oneToOne">
      <div class="text-left">
        <h3>One-to-One Consultation</h3>
        <p>
          Arrange an appointment with one of our jewellery specialists for tailored advice on your jewellery purchase.
          Our consultants will show a selection of designs based on your preferences and price range to help find the
          ideal fit.
        </p>
        <div class="buttons">
          <a id="st1-showroom" @click.prevent="handleBookNow">Book Now</a>
        </div>
        <p class="covidText d-none">
          Coronavirus Update: all showrooms are closed, but you can now book face-to-face appointments in advance for
          London and Manchester, reopening 13th April. For earlier meetings, please choose an online consultation.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO: add styles
const handleChatNow = () => {
  console.log('handleChatNow')
}
const handleBookNow = () => {
  console.log('handleBookNow')
}
</script>

<style scoped></style>
