export async function fetchProductsByConfig({ countryId, languageId, currencyId, configs }) {
  try {
    const response = await $fetch(`/api/v1/product/products-by-config`, {
      method: 'POST',
      body: {
        Configs: configs,
        CountryId: countryId,
        LanguageId: languageId,
        CurrencyId: currencyId,
      },
    })

    return response || null
  } catch (error) {
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}
