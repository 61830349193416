<template>
  <div class="md:container">
    <div class="showcases">
      <div v-for="item in showcaseItems" :key="item.icon" class="showcase">
        <i :class="['icon77', item.icon]"></i>
        <h3 class="showcase__title">{{ item.title }}</h3>
        <p class="showcase__description body-12-aa">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getShowcaseSectionItems } from '@/constants/home'

const { t } = useI18n()

const showcaseItems = computed(() => getShowcaseSectionItems(t))
</script>

<style scoped lang="scss">
.showcases {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 text-center mt-9 md:gap-6;
}

.showcase {
  @apply mb-9;

  &__title {
    @apply font-domaine my-1.5;
  }
  &__description {
    @apply px-9 md:px-0 lg:px-9;
  }
}
</style>
